import styled, { css } from 'styled-components';
import { commonVariables } from '~/styles/variables';
import { YellowButton, WhiteButton } from '~/styles/buttons';

// todo: merge this with breakpoints in "mediaQueries.jsx", duplicate logic....
const tabletBreakpoint = '750px';
const mobileBreakpoint = '450px';
const smallScreenBreakpoint1064px = '1064px';
const FHDBreakpoint1920px = '1920px';
const UltraHDreakpoint3840px = '3840px';

const Container = styled.div`
    max-width: ${commonVariables.maxWidths.container};
    width: 100%;
    margin: 0 auto;
`;

const ContainerBig = styled(Container)`
    max-width: ${commonVariables.maxWidths.bigContainer};
`;

const SectionHeading = styled.h2`
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 46px;
    letter-spacing: -0.25px;
    color: #000000;
    text-align: center;

    @media (max-width: ${tabletBreakpoint}) {
        text-align: center;
        font-size: 32px;
    }
`;

const SectionContainer = styled.div`
    background-color: #edf2f4;
    padding: 8rem 3rem;

    @media (max-width: 824px) {
        padding: 3.5rem 1rem;
    }

    b {
        font-weight: 700;
    }

    u {
        text-decoration: underline;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 850px;
    width: 100%;
    margin: 0 auto;

`;

const SmallerHeading = styled.div`
    font-size: 18px;
    text-align: center;
`;

const SectionSmallerHeading = styled.div`
    font-weight: bold;
    font-size: 33px;
    line-height: 43px;
    letter-spacing: -0.25px;
    color: #2d2d2d;
    margin-bottom: 35px;

    @media (max-width: ${tabletBreakpoint}) {
        text-align: center;
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 16px;
    }
`;

const SectionDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: ${tabletBreakpoint}) {
        align-items: center;
    }
`;

const SectionDescription = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: -0.25px;
    color: #707070;

    @media (max-width: ${tabletBreakpoint}) {
        font-size: 16px;
        line-height: 29px;
        text-align: center;
    }
`;

const Image = styled.img`
    @media (max-width: ${tabletBreakpoint}) {
        width: 240px;
        margin-top: 34px;
    }
`;

const SectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    &.right-image {
        @media (max-width: ${tabletBreakpoint}) {
            flex-direction: column;
        }
    }

    &.left-image {
        @media (max-width: ${tabletBreakpoint}) {
            flex-direction: column-reverse;
        }
    }
`;

const CTAButton = styled(YellowButton)`
    @media (max-width: ${tabletBreakpoint}) {
        margin: 0 auto;
        display: none !important;
    }
`;

const CTAMobileButton = styled(YellowButton)`
    display: none !important;

    @media (max-width: ${tabletBreakpoint}) {
        display: inline-block !important;
    }
`;

const CTAWhiteButton = styled(WhiteButton)`
    @media (max-width: ${tabletBreakpoint}) {
        margin: 0 auto;
        display: none;
    }
`;

const CTAWhiteMobileButton = styled(WhiteButton)`
    display: none;

    @media (max-width: ${tabletBreakpoint}) {
        display: inline-block;
    }
`;

const PoliciesWrapper = styled(SectionContainer)`
    color: black;
    line-height: 1.7;
    font-size: 18px;
    font-weight: 300;
    width: 100%;
    max-width: 978px;
    margin: auto;
    padding-top: 30px;
    background: transparent;

    a {
        color: #0cc8a8 !important;
    }

    a:hover {
        text-decoration: underline;
    }

    h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 130%;
        letter-spacing: -1px;
        color: #0cc8a8;
        margin-bottom: 30px;
    }

    h2 {
        font-weight: bold;
        font-size: 28px;
        line-height: 34px;
        letter-spacing: 1.09px;
        margin: 2rem 0 1rem 0;

        @media (max-width: 840px) {
            font-size: 26px;
        }

        @media (max-width: 540px) {
            font-size: 24px;
        }
    }

    h3 {
        font-size: 24px;
        letter-spacing: 0.82px;
        margin: 2rem 0 1rem 0;
        font-weight: 300;

        @media (max-width: 840px) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;
        letter-spacing: 0.82px;
        margin-bottom: 0.7rem;
        font-weight: 300;
    }

    h5 {
        font-size: 18px;
        letter-spacing: 0.82px;
        margin-bottom: 0.5rem;
        font-weight: 300;
    }

    h6 {
        font-size: 16px;
        letter-spacing: 0.82px;
        margin-bottom: 0.5rem;
        font-weight: 300;
    }

    p {
        margin-bottom: 1rem;
    }

    ol,
    dl,
    ul {
        margin-bottom: 2rem;
    }

    ul {
        list-style: none;
    }

    ul li:before {
        /* the custom styled bullets */
        background-color: #14ccbb;
        content: '';
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 2px;
        height: 9px;
        width: 9px;
    }

    li p {
        margin: 4px 0px;
        display: inline;
    }

    a {
        text-decoration: none;
        scroll-margin: 90px;
    }


    table {
        border-collapse: collapse;
        margin: 25px 0;
        font-size: 0.9em;
        font-family: sans-serif;
        min-width: 400px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);


        @media (max-width: 400px) {
            min-width: 0;
        }

        thead {
            tr {
                background-color: #009879;
                color: #ffffff;
                text-align: left;
            }
        }

        th, td {
            padding: 12px 15px;
        }

        tr {
            border-bottom: 1px solid #dddddd;

            &:nth-of-type(even) {
                background-color: #f3f3f3;
            }

            &:last-of-type {
                border-bottom: 2px solid #009879;
            }
        }

        tbody {
            tr {
                &:hover {
                    background-color: #e8e8e8;
                }
            }
        }
    }
`;

export {
    ContainerBig,
    Container,
    SectionHeading,
    SectionContainer,
    SmallerHeading,
    ContentWrapper,
    SectionSmallerHeading,
    Image,
    SectionDescriptionWrapper,
    SectionDescription,
    SectionWrapper,
    CTAButton,
    CTAMobileButton,
    CTAWhiteButton,
    CTAWhiteMobileButton,
    tabletBreakpoint,
    mobileBreakpoint,
    PoliciesWrapper,
    smallScreenBreakpoint1064px,
    FHDBreakpoint1920px,
    UltraHDreakpoint3840px
};
